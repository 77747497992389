.SearchFilters {
  display: flex;
  flex: 1;
  max-width: 100%;

  & > * {
    flex-basis: 25%;
    max-width: 25%;
  }
}
