body {
  background-color: #f0f2f5;
  overflow-y: scroll;

  .SpinningLogo {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  }

  .notistack-MuiContent-default {
    background: #15a8bf;

    .notistack-snackbar-buttons-wrapper {
      display: flex;
      gap: 8px;

      .Button {
        & > * {
          background: #f0f2f5;
          color: #666;
        }
      }
    }
  }

  .notistack-MuiContent-success {
    background: #097b3b;
  }
}
