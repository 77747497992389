.ProductDetailsPopup {
  .MuiDialog-paper {
    height: calc(100% - 64px);
    background-color: transparent;
  }

  .ProductDetailsPopup__loading {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #f0f2f5;
  }

  .ProductDetailsView__snackbar {
    opacity: 0.9;
    .MuiPaper-root {
      background-color: #0e7585;
    }
  }
}
