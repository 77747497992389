.ProductDetailsLayout {
  background-color: white;
  display: flex;
  flex-direction: column;

  .ProductDetailsLayout__appbar {
    margin: 8px 0px;
  }

  .ProductDetailsLayout__productDetails {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 53.984px - 16px);
  }
}
