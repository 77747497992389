.ProductNotFoundLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > * {
    .div:empty {
      display: none;
    }
  }

  .ProductNotFoundLayout__feedback {
    .MuiLinearProgress-root {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .ProductNotFoundLayout__top {
    background-color: white;
    display: flex;
    flex-direction: column;

    & > div:empty {
      display: none;
    }

    .ProductNotFoundLayout__appbar {
      margin-top: 8px;
    }

    .ProductNotFoundLayout__banner {
      align-self: center;
      margin-top: 56px;
      margin-bottom: 56px;

      & + .ProductNotFoundLayout__divider {
        margin-top: 32px;
      }

      &:empty {
        & + .ProductNotFoundLayout__divider {
          margin-top: 448px;
        }
      }
    }
  }

  .ProductNotFoundLayout__bottom {
    display: flex;
    flex-direction: column;

    .ProductNotFoundLayout__product-request-notification {
      display: flex;
      align-self: center;
      width: 60%;
      margin-top: 56px;
    }
  }

  .ProductNotFoundLayout__footer {
    margin-top: auto;
  }
}
