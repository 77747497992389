@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

.MainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > * {
    .div:empty {
      display: none;
    }
  }

  .MainLayout__feedback {
    .MuiLinearProgress-root {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .MainLayout__top {
    background-color: white;
    display: flex;
    flex-direction: column;

    & > div:empty {
      display: none;
    }

    .MainLayout__appbar {
      margin-top: 8px;
    }

    .MainLayout__banner {
      align-self: center;
      margin-top: 56px;

      & + .MainLayout__search {
        margin-top: 56px;
      }

      &:empty {
        & + .MainLayout__search {
          margin-top: 141.5px;
        }
      }
    }

    .MainLayout__search {
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 60%;
      margin-bottom: 141.5px;

      .MainLayout__searchbar {
        width: 100%;
      }

      .MainLayout__search-filters {
        display: flex;
        max-width: 100%;
      }
    }

    .MainLayout__filter-controls {
      display: flex;
      align-self: center;
      justify-content: space-between;
      margin-bottom: 8px;
      width: 60%;
    }
  }

  .MainLayout__bottom {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    gap: 16px;

    margin-bottom: 64px;

    & > div:empty {
      display: none;
    }

    .MainLayout__collection-info {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      width: 60%;
      margin-top: 0px;

      .ManufacturerCollectionInfo {
        display: flex;
        flex-direction: column;
        // background-color: #15a8bf;
        // border: 1px solid #15a8bf;
        border-radius: 8px;
        // padding: 8px;
        // background: tint(#b1bccb, 50%);
        justify-content: space-between;
        // padding: 8px 16px;
        // box-shadow: rgba(0, 0, 0, 0.25) 0 2px 8px;
        width: 100%;
        gap: 8px;
        // align-self: center;

        .ManufacturerCollectionInfo__column {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .ManufacturerCollectionInfo__row {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 32px;

          .ManufacturerCollectionInfo__name {
            margin-top: auto;
          }

          .ManufacturerCollectionInfo__description {
            display: flex;
            width: 50%;
          }

          .ManufacturerCollectionInfo__buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
          }
        }

        .ManufacturerCollectionInfo__notification {
          display: flex;
          align-items: center;
          justify-content: center;

          .CollectionNotification {
            .MuiPaper-root {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px 32px;
            }

            .CollectionNotification-message {
              cursor: default;
              display: flex;
              align-items: center;
              margin-left: 16px;
            }

            .MuiButton-root {
              padding-left: 23px;
              padding-right: 23px;
            }
          }
        }

        .ManufacturerCollectionInfo__buttons {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 16px;
        }

        .ManufacturerCollectionInfo__description {
          display: flex;
          width: 80%;
        }
      }
    }

    .MainLayout__divider_collection {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      align-self: center;
      width: 60%;
      margin-top: -8px;
      margin-bottom: -8px;
    }

    .MainLayout__chips {
      width: 60%;
      align-self: center;
    }

    .MainLayout__result-info {
      display: flex;
      align-self: center;
      width: 60%;
    }

    .MainLayout__top-pagination {
      display: flex;
      align-self: center;
      justify-content: center;
      width: 60%;
    }

    .MainLayout__product-cards {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      justify-content: center;
      gap: 20px;
      width: 80%;
    }

    .MainLayout__bottom-pagination {
      align-self: center;
      justify-content: center;
      margin-top: 48px;
    }
  }

  .MainLayout__footer {
    margin-top: auto;
  }

  .MainLayout__notifications {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
