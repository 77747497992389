.FilterControls {
  display: flex;
  width: 100%;

  .FilterControls__left {
    display: flex;
    flex: 1;
    gap: 16px;
  }

  .FilterControls__right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
